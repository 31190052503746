import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
// import { Icon } from '@iconify/react';
import { pageTitle } from '../../helpers/PageTitle';

export default function PNEHoney() {
  pageTitle('PNE Good Foods');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title=" "
        subTitle="PNE Good Foods"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src="/images/portfolio/pne/pne.png" alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in" style={{display:"flex", justifyContent:"center"}}>
            
            <div className="cs_portfolio_details_right col-lg-11">
              <h2>Project Summary</h2>
              <p>
                We embarked on a comprehensive project to create a shopping 
                website that went beyond just functionality—it was about 
                building a complete digital experience. Our team was responsible 
                for developing a user-friendly and visually captivating e-commerce 
                platform. The website was designed with the customer in mind, 
                featuring an intuitive interface that made navigation and shopping 
                effortless.
                
              </p>
              <p>
                Beyond the technical aspects, we also took charge of the marketing 
                efforts. Our strategy included a blend of digital marketing techniques 
                aimed at driving traffic to the site and converting visitors into 
                customers. From social media campaigns to SEO optimization, we 
                ensured the brand reached its target audience effectively.
              </p>
              <p>
                Branding was another critical element of this project. We crafted a 
                unique brand identity that resonated with the target audience and set 
                the product apart in a competitive market. This included developing a 
                logo, color scheme, and overall aesthetic that aligned with the brand’s 
                values and message.
              </p>
              <p>
                Our design work was integral to the project’s success, creating an engaging 
                visual narrative that enhanced the user experience. We focused on creating 
                a seamless journey from the moment customers landed on the site to the 
                checkout process, ensuring every detail was polished and purposeful. 
                This project highlights our ability to integrate design, marketing, 
                and development into a cohesive and impactful digital presence.
              </p>
            </div>
          </div>
          <Spacing lg="115" md="60" />
          <div className="cs_portfolio_details_gallery">
            <div>
              <img src="/images/portfolio/pne/2l.png" alt="Thumb" />
            </div>
            <div>
              <img src="/images/portfolio/pne/1.png" alt="Thumb" />
            </div>
            <div>
              <img src="/images/portfolio/pne/2.png" alt="Thumb" />
            </div>
          </div>
        </div>
        <Spacing lg="90" md="60" />
        {/* <div className="cs_page_navigation cs_center">
          <div>
            <a href="/" className="cs_text_btn cs_type1">
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </a>
          </div>
          <div>
            <a href="/" className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </a>
          </div>
        </div> */}
      </div>
      <Spacing lg="150" md="80" />
    </>
  );
}
