import { Icon } from '@iconify/react';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
const addressDataList = [
  ' Hyderabad, Telangana.',
  '+91 939 805 5569',
  'connect@infosagesolutions.com',
];
const serviceMenuList = [
  {
    title: 'Branding & Logo Design',
    href: '/service/branding-logo',
  },
  {
    title: 'Web Design',
    href: '/service/web-design',
  },
  
  {
    title: 'Infographics',
    href: '/service/infographics',
  },
  {
    title: 'Web Development',
    href: '/service/web-development',
  },
  {
    title: 'Content Writing',
    href: '/service/content-writing',
  },
  {
    title:'Conversion Rate Optimization',
    href:'/service/cro'
  },
  
];
const serviceMenuList1 = [
  {
    title: 'Video Content',
    href: '/service/video-content',
  },
  {
    title: 'Script Writing',
    href: '/service/script-writing',
  },
  {
    title: 'Product Launch & GTM Strategy Service',
    href: '/service/product-launch-strategy',
  },
  {
    title: 'Social Media Marketing',
    href: '/service/social-media-marketing',
  },
  {
    title: 'Search Engine Optimization',
    href: '/service/seo',
  },
  {
    title: 'Performance Marketing',
    href: '/service/performance-marketing',
  },
];
const LinksMenuList = [
  {
    title: 'Home',
    href: '/',
  },
  {
    title: 'About',
    href: '/about',
  },
  {
    title: 'Services',
    href: '/service',
  },
  {
    title: 'Portfolio',
    href: 'portfolio',
  },
  {
    title: 'Blog',
    href: '/blog',
  },
  {
    title: 'Contact',
    href: '/contact',
  },
];
const policyMenuList = [
  {
    title: 'Terms of Use',
    href: '#',
  },
  {
    title: 'Privacy Policy',
    href: '#',
  },
];
const socialBtnList = [
  {
    icon: 'fa6-brands:linkedin-in',
    href: 'https://www.linkedin.com/company/infosage-digital/',
  },
  {
    icon: 'fa6-brands:instagram',
    href: 'https://www.instagram.com/infosagesolutions?igsh=MW0yaTZwMDJmb3h2aQ==',
  },
  {
    icon: 'fa6-brands:youtube',
    href: 'https://youtube.com/@infosagesolutions?si=hM7lDdvmr2XeXDWZ',
  },
  {
    icon: 'fa6-brands:facebook-f',
    href: 'https://www.facebook.com/share/eZ2tBKts3ija9Lim/?mibextid=qi2Omg',
  },
];
export default function Footer() {
  const email=useRef();

  const baseUrl = "https://mailtest-one.vercel.app";

  const sendEmail = async (e) => {
    e.preventDefault();
    var data = ` Email: ${email.current.value} `;
    let dataSend = {
      email: "connect@infosagesolutions.com",
      subject: "digi News Letter Subscriber",
      message: data,
    };
    try {
      const res = await fetch(`${baseUrl}/email/sendEmail`, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      // console.log(res);
      if (res.status > 199 && res.status < 300) {
        alert("Subscription Successful, you will receive regular updates.");
      } else {
        alert(
          "Some error have occured while sending the mail. Please try again."
        );
      }
    } catch (error) {
      alert(error);
    }
    email.current.value = "";
  };


  return (
    <footer
      className="cs_fooer cs_bg_filed"
      style={{ backgroundImage: 'url(/images/footer_bg.jpeg)' }}
    >
      <div className="cs_fooer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <div className="cs_text_widget">
                  <img src="/images/logo.png" alt="Logo" style={{width:"195px"}}/>
                </div>
                <ul className="cs_menu_widget cs_mp0">
                  {addressDataList.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Services</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {serviceMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="cs_footer_item">
                {/* <h2 className="cs_widget_title">Services</h2> */}
                <br/>
                <ul className="cs_menu_widget cs_mp0">
                  {serviceMenuList1.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Quick Links</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {LinksMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Subscribe Newsletter </h2>
                <div className="cs_newsletter cs_style_1">
                  <div className="cs_newsletter_text">
                    We make sure to only send emails that are noteworthy and
                    pertinent to the recipient.
                  </div>
                  <form action="#" className="cs_newsletter_form">
                    <input
                      type="email"
                      className="cs_newsletter_input"
                      placeholder="Email address"
                      ref={email}
                    />
                    <button className="cs_btn cs_style_1" onClick={sendEmail}>
                      Submit
                      <span>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cs_bottom_footer">
          <div className="cs_bottom_footer_left">
            <div className="cs_social_btns cs_style_1">
              {socialBtnList.map((item, index) => (
                <Link to={item.href} className="cs_center" key={index}>
                  <Icon icon={item.icon} />
                </Link>
              ))}
            </div>
          </div>
          <div className="cs_copyright">Copyright © 2024 Infosage Solutions.</div>
          <div className="cs_bottom_footer_right">
            <ul className="cs_footer_links cs_mp0">
              {policyMenuList.map((item, index) => (
                <li key={index}>
                  <Link to={item.href}>{item.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
