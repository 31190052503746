import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. What is Conversion Rate Optimization (CRO)?',
    content:
      'Conversion Rate Optimization (CRO) is the process of improving your website to increase the percentage of visitors who take a desired action, such as making a purchase, signing up for a newsletter, or filling out a contact form. CRO involves analyzing user behavior, identifying areas for improvement, and implementing strategic changes to enhance the overall effectiveness of your website.',
  },
  {
    title: '02. Why is CRO important for my business?',
    content:
      'CRO is crucial because it helps you get more value from your existing website traffic. By optimizing your website’s conversion rates, you can increase sales, generate more leads, and improve the overall return on investment (ROI) of your digital marketing efforts. It’s a cost-effective way to boost your business performance without needing to drive more traffic to your site.',
  },
  {
    title: '03. How do you determine what changes need to be made for CRO?',
    content:
      'We begin with a comprehensive audit of your website, using tools like heatmaps, user session recordings, and analytics to understand user behavior. We then identify pain points, such as high bounce rates or abandoned shopping carts, and develop hypotheses on what changes could improve conversion rates. These hypotheses are tested through A/B testing to determine the most effective solutions.',
  },
  {
    title: '04. What is A/B testing, and how does it work?',
    content:
      'A/B testing is a method where two versions of a webpage (Version A and Version B) are shown to different segments of your audience simultaneously. The performance of each version is tracked to see which one leads to a higher conversion rate. This data-driven approach helps us determine which changes have the most positive impact on user behavior.',
  },
  {
    title: '05. How long does it take to see results from CRO?',
    content:
      ' The timeline for seeing results can vary depending on the scope of the project and the volume of traffic your website receives. Typically, initial insights can be gathered within a few weeks, and significant improvements in conversion rates can be observed within 1-3 months. However, CRO is an ongoing process, and continuous testing and optimization are necessary to maintain and improve results over time.',
  },
];
const serviceListData = [
  {
    title: 'Data-Driven Insights',
    subtitle:
      ' We use advanced analytics to gain deep insights into user behavior, helping us identify bottlenecks and opportunities for improvement.',
    imgUrl: '/images/sd/cro/1.png',
    href: '#',
  },
  {
    title: 'Continuous Improvement',
    subtitle:
      ' CRO is not a one-time task; we continuously test and refine your website to ensure ongoing optimization and sustained results.',
    imgUrl: '/images/sd/cro/2.png',
    href: '#',
  },
  {
    title: 'A/B Testing',
    subtitle:
      'We conduct rigorous A/B testing on various elements of your website to determine what works best for your audience, leading to higher conversion rates.',
    imgUrl: '/images/sd/cro/3.png',
    href: '#',
  },
  {
    title: 'Increased ROI',
    subtitle:
      'By improving your conversion rates, we help you get more value from your existing traffic, boosting your overall return on investment.',
    imgUrl: '/images/sd/cro/4.png',
    href: '#',
  },
];

export default function CRO() {
  pageTitle('Conversion Rate Optimization');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Turning Traffic into Conversions"
        subTitle="Conversion Rate Optimization"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/cro/cro.png"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Maximize the effectiveness of your website with our Conversion Rate Optimization (CRO) 
             services. We analyze user behavior, identify barriers to conversion, and implement 
             strategic improvements to enhance your website's performance. By optimizing key 
             elements such as layout, content, and calls-to-action, we help turn more visitors 
             into customers, increasing your ROI and ensuring that every click counts towards 
             your business growth.
            </p>
            <p>
             Our CRO approach is data-driven and tailored to your unique goals. We focus on 
             making your website more persuasive and user-friendly, driving higher conversions 
             and delivering measurable results.

            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Boost your conversions with data-driven CRO strategies. We optimize your 
                site’s user experience and content, driving higher engagement and turning 
                visitors into loyal customers.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Audit & Analysis"
                  subTitle="We start with a comprehensive audit of your website to identify current conversion rates, user behavior, and potential issues that are hindering conversions."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Hypothesis Development"
                  subTitle="Based on our analysis, we develop hypotheses on how to improve conversion rates, focusing on areas that will have the most significant impact."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="A/B Testing"
                  subTitle="We implement A/B testing on critical elements of your site, such as headlines, calls to action, and page layouts, to determine the most effective variations."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Implementation"
                  subTitle="Successful test results are implemented across your website. We ensure that these changes are seamlessly integrated into your existing design and functionality."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Monitoring & Reporting"
                  subTitle="We continuously monitor the performance of the changes and provide detailed reports on the impact of the optimization efforts."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Ongoing Optimization"
                  subTitle="CRO is an ongoing process. We continually analyze, test, and refine your website to ensure it consistently performs and adapts to users at its best."
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
