import React from 'react';
import Spacing from '../Spacing';
import Cta from '../Cta';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';
// import HeroStyle3 from '../Hero/HeroStyle3';
import IconBoxStyle2 from '../IconBox/IconBoxStyle2';
import PortfolioSlider from '../Slider/PortfolioSlider';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import PostGridStyle2 from '../PostGrid/PostGridStyle2';
import Marquee from '../Marquee';


// import Award from '../Award';
// import Accordion from '../Accordion';
// import TestimonialSlider from '../Slider/TestimonialSlider';
// import PostCarousel from '../Slider/PostCarousel';
// import FunFact from '../FunFact';
// import About from '../About';
// import WhyChose from '../WhyChose';
// import Service from '../Service';
// import Portfolio from '../Portfolio';
// import Button from '../Button';
// import Hero from '../Hero';
// const funfactData = [
//   { title: 'Happy Customers', number: '22k' },
//   { title: 'Work’s Completed', number: '15k' },
//   { title: 'Skilled Team Members', number: '121' },
//   { title: 'Most Valuable Awards', number: '15' },
// ];

const postData = [
  {
    date: ' ',
    title: 'Grow Your Business with Google Ads – Understanding Google Ads in Simple Steps!',
    thumbnailSrc: '/images/blog/1.png',
    href: 'https://www.infosage.in/blog/google-ads-master/',
  },
  {
    date: ' ',
    title: 'The Top 7 Branding Strategies for Small Businesses.',
    thumbnailSrc: '/images/blog/4.png',
    href: 'https://www.infosage.in/blog/branding-strategies-business/',
  },
  {
    date: ' ',
    title: 'Simplifying Local SEO: The Key to Dominating Your Local Market.',
    thumbnailSrc: '/images/blog/2.png',
    href: 'https://www.infosage.in/blog/simplifying-local-seo/',
  },
  {
    date: ' ',
    title: 'Graphic Designing and Why It Is Important in Digital Marketing and Branding ?',
    thumbnailSrc: '/images/blog/3.png',
    href: 'https://www.infosage.in/blog/what-is-graphic-designing/',
  },
];

// const whyChoseFeatureData = [
//   {
//     title: 'Talented, professional & expert team',
//     content:
//       'Our team, specializing in strategic digital marketing, are not partners with the world is leading brands. Breaking from the norm, we push boundaries and merge.',
//   },
//   {
//     title: 'Highly accuracy AI based system',
//     content:
//       'Our team, specializing in strategic digital marketing, are not partners with the world is leading brands. Breaking from the norm, we push boundaries and merge.',
//   },
//   {
//     title: 'Secret successful brand strategy formula',
//     content:
//       'Our team, specializing in strategic digital marketing, are not partners with the world is leading brands. Breaking from the norm, we push boundaries and merge.',
//   },
// ];
// const serviceListData = [
//   {
//     title: 'WP Development',
//     subtitle:
//       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.',
//     imgUrl: '/images/creative-agency/service_7.jpeg',
//     href: '/service/service-details',
//   },
//   {
//     title: 'UI/UX Design',
//     subtitle:
//       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.',
//     imgUrl: '/images/creative-agency/service_8.jpeg',
//     href: '/service/service-details',
//   },
//   {
//     title: 'Branding',
//     subtitle:
//       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.',
//     imgUrl: '/images/creative-agency/service_9.jpeg',
//     href: '/service/service-details',
//   },
//   {
//     title: 'Social Ad Campaign',
//     subtitle:
//       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium lorema doloremque laudantium, totam rem aperiam, eaque ipsa quae.',
//     imgUrl: '/images/creative-agency/service_10.jpeg',
//     href: '/service/service-details',
//   },
// ];
// const portfolioData = [
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/creative-agency/portfolio_1.jpeg',
//     title: 'Awesome colorful artwork',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/creative-agency/portfolio_2.jpeg',
//     title: 'Admin dashboard UI design',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/creative-agency/portfolio_3.jpeg',
//     title: 'Product designing with brand',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/creative-agency/portfolio_4.jpeg',
//     title: 'Kids education website design',
//     btnText: 'See Project',
//   },
// ];
const portfolioData = [
  {
    thumbnailSrc: '/images/home/4.gif',
    miniTitle: ' ',
    title: 'Digital Marketing',
    subTitle:
      'We maximize your digital footprint with tailored marketing strategies <br/> that drive traffic, enhance engagement, and boost conversions.',
    href: '/portfolio/portfolio-details',
  },
  {
    thumbnailSrc: '/images/home/1.gif',
    miniTitle: ' ',
    title: 'Video Production',
    subTitle:
      'From the initial storyboard to the final edit, we create visually stunning <br/> videos that tell your brands story and engage your audience.',
    href: '/portfolio/portfolio-details',
  },
  {
    thumbnailSrc: '/images/home/3.gif',
    miniTitle: ' ',
    title: 'Branding Design',
    subTitle:
      'Creating a visual identity that perfectly reflects your brand ethos<br/> and speaks directly to your audience.',
    href: '/portfolio/portfolio-details',
  },
  {
    thumbnailSrc: '/images/home/2.gif',
    miniTitle: ' ',
    title: 'Web Development',
    subTitle:
      'Crafting robust websites that meet your business demands and deliver <br/> a seamless user experience for enhanced engagement and performance.',
    href: '/portfolio/portfolio-details',
  },
];
// const awardData = [
//   {
//     brand: 'Behance',
//     title: 'UI/UX design of the month',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'December 12, 2023',
//     awardImgUrl: '/images/creative-agency/award_img_1.svg',
//   },
//   {
//     brand: 'Awwwards',
//     title: 'CSS awards design',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'January 05, 2022',
//     awardImgUrl: '/images/creative-agency/award_img_2.svg',
//   },
//   {
//     brand: 'Google',
//     title: 'Website of the day',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'March 20, 2021',
//     awardImgUrl: '/images/creative-agency/award_img_3.svg',
//   },
// ];
// const testimonialData = [
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Ansari Patron',
//     avatarDesignation: 'CEO at Delta',
//   },
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Jhon Doe',
//     avatarDesignation: 'Manager at Delta',
//   },
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Ramatam Coo',
//     avatarDesignation: 'MD at Delta',
//   },
// ];
// const faqData = [
//   {
//     title: '01. I need your services and how can i contact you throw email?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '02. What are the different types of service we provide?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '03. What are the different stages of the working process?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '04. What is the difference between direct and digital marketing?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
//   {
//     title: '05. How can i payment proceed after complete project?',
//     content:
//       'Marketing eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born.',
//   },
// ];

// const postData = [
//   {
//     thumbnailSrc: '/images/creative-agency/post_1.jpeg',
//     title: 'How to keep fear from ruining your art business with confident',
//     date: '07 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/creative-agency/post_2.jpeg',
//     title: 'Artistic mind will be great for creation anything',
//     date: '22 Apr 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/creative-agency/post_3.jpeg',
//     title: 'AI will take over all job for human within few years',
//     date: '13 May 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/creative-agency/post_4.jpeg',
//     title: 'Your agency need to replace some artistic mind people',
//     date: '15 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/creative-agency/post_1.jpeg',
//     title: 'How to keep fear from ruining your art business with confident',
//     date: '07 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/creative-agency/post_2.jpeg',
//     title: 'Artistic mind will be great for creation anything',
//     date: '22 Apr 2023',
//     url: '/portfolio/portfolio-details',
//   },
//   {
//     thumbnailSrc: '/images/creative-agency/post_3.jpeg',
//     title: 'AI will take over all job for human within few years',
//     date: '13 May 2023',
//     url: '/portfolio/portfolio-details',
//   },
//   {
//     thumbnailSrc: '/images/creative-agency/post_4.jpeg',
//     title: 'Your agency need to replace some artistic mind people',
//     date: '15 Mar 2023',
//     url: '/portfolio/portfolio-details',
//   },
// ];

export default function Home() {
  pageTitle('Infosage Solutions');
  return (
    <>
    <div style={{marginTop:"75px", position: 'relative', height: '100vh', overflow: 'hidden' }}>
    <video
      autoPlay
      loop
      muted
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    >
    <source src="/images/homevideo/video.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
   <div style={{ position: 'relative', zIndex: '1', color: '#fff' }}>
        {/* Your content goes here */}
        <h1>Welcome to My Website</h1>
      </div>
 
</div>
 {/* <HeroStyle3
                title="Unlocking Digital Horizons <br/> for Brands"
                scrollingText="An amalgamation of artistry and analytics to craft digital journeys."
                thumbnailSrc={
                    '/images/studio-agency/hero_bg_dark.jpg'
                }
              /> */}
     
      {/* <Hero
        title={[
          'London Based Creative Agency',
          '25+ Years of Experience',
          '30+ Worldwide Partnership',
          'Take World-class Service',
        ]}
        subtitle="Craft Distinct Brand Image with Expert Guidance & Fresh Approach."
        videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
        bgUrl="/images/creative-agency/hero_video_bg_1.jpeg"
      /> */}
      {/* <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" /> */}
      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Exceeding expectations our <br />service is our promise"
            subTitle="Services"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <div className="row cs_gap_y_45">
            <div className="col-lg-4 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_1.svg"
                title="Design and Development"
                subTitle=" "
                dataLinks={[
                  'service/branding-logo',
                  'service/web-design',
                  'service/infographics',
                  'service/web-development',
                ]}
                features={[
                  'Branding & Logo Design',
                  'Web Design', 
                  'Infographics',
                  'Web Development',
                  
                ]}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_2.svg"
                title="Content Scripting"
                subTitle=" "
                dataLinks={[
                  'service/content-writing',
                  'service/cro',
                  'service/video-content',
                  'service/script-writing'
                ]}
                features={[
                  'Content Writing',
                  'Conversion Rate Optimization',
                  'Video Content',
                  'Script Writing',
                ]}
              />
            </div>
            <div className="col-lg-4 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_3.svg"
                title="Digital Strategy"
                subTitle=" "
                dataLinks={[
                  'service/product-launch-strategy',
                  'service/social-media-marketing',
                  'service/seo',
                  'service/performance-marketing'
                ]}
                features={[
                  'Product Launch and GTM Strategy',
                  'Social Media and Influencer Marketing',
                  'Search Engine Optimization (SEO)',
                  'Performance Marketing ',
                ]}
              />
            </div>
            {/* <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_4.svg"
                title="Web Development"
                subTitle="Exploring the World of Web Development in zivan digital agency."
                features={[
                  'UI/UX Design',
                  'React Application',
                  'eCommerce Development',
                  'Business Website',
                  'App Development',
                  'Web Application',
                ]}
              />
            </div> */}
          </div>
        </div>
        <Spacing lg="143" md="75" />
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="A Team of Experienced Digital Marketers, Designers, and Developers" subTitle=" " />
          <Spacing lg="85" md="45" />
        </div>
        <PortfolioSlider data={portfolioData} />
        <Spacing lg="150" md="80" />
      </section>

      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="Our Approach" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Planning and Ideation"
                  subTitle="We start by understanding your goals and brainstorming creative ideas that align with your vision, ensuring a solid foundation for your brand."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Designing and Development"
                  subTitle="Our team designs and develops tailored solutions, transforming ideas into functional, visually appealing websites/apps in sync with the ideation."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Data and Optimization"
                  subTitle="We test our approaches with data-driven techniques to ensure tried-and-tested results with our industry experience and keep optimizing for better results."
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>


      <Cta
        title="Is there a specific project or goal <br/>that you have in mind?"
        btnText="Contact Us"
        btnUrl="/contact"
        bgUrl="/images/creative-agency/cta_bg.jpeg"
        variant="cs_type_1"
      />


      <section className="cs_shape_animation_3">
        <div className="cs_shape_1 position-absolute">
          <svg
            width={509}
            height={458}
            viewBox="0 0 509 458"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx={254} cy={229} r="228.5" stroke="currentColor" />
            <circle
              cx={26}
              cy={237}
              r="25.5"
              className="anio_3_c_1"
              fill="white"
              stroke="currentColor"
            />
            <circle cx={26} cy={237} r={15} fill="currentColor" />
            <circle
              cx={483}
              cy={229}
              r="25.5"
              className="anio_3_c_1"
              fill="white"
              stroke="currentColor"
            />
            <circle cx={483} cy={229} r={15} fill="currentColor" />
          </svg>
        </div>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some recent news" subTitle="Our Blog" />
          <Spacing lg="85" md="45" />
          <PostGridStyle2 data={postData} />
        </div>
      </section>
      <Spacing lg="135" md="70" />
      <Marquee text="We Create Design - Build App - Website - Branding - SEO" />

      <Spacing lg="135" md="70" />


      {/*<About
        thumbnail="/images/creative-agency/about_1.jpeg"
        uperTitle="Who We Are"
        title="Full-stack creatives and designing agency"
        subTitle="Our team, specializing in strategic digital marketing, partners with the world's leading brands. Breaking from the norm, we push boundaries and merge imaginative thinking, consumer behavior,
        and data-driven design with advanced technology to deliver unparalleled brand experiences."
        featureList={[
          'Designing content with AI power',
          'Trending marketing tools involve',
          'Powerful market strategy use',
        ]}
        btnText="Learn More"
        btnUrl="/about"
      />
      <Spacing lg="185" md="75" />
      <WhyChose
        sectionTitle="We have depth of market knowledge"
        sectionSubTitle="Why Choose Us"
        whyChoseFeatureData={whyChoseFeatureData}
        thumbnailSrc="/images/creative-agency/why_choose_us_img_3.jpeg"
      />
      <Spacing lg="150" md="80" />
      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Our core services"
            subTitle="Services"
            variantColor="cs_white_color"
          />
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some featured work" subTitle="Portfolio" />
          <Spacing lg="85" md="45" />
          <Portfolio data={portfolioData} />
          <Spacing lg="26" md="30" />
          <div className="text-center">
            <Button btnText="See All Project" btnUrl="/portfolio" />
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#4F4747"
              />
            </g>
          </svg>
        </div>
        <div className="container">
          <SectionHeading
            title="Our prize achievement"
            subTitle="Awards"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div>
        <Spacing lg="150" md="80" />
      </section>
      <TestimonialSlider
        layeredImages={[
          '/images/creative-agency/layer_img_1.jpeg',
          '/images/creative-agency/layer_img_2.jpeg',
          '/images/creative-agency/layer_img_3.jpeg',
          '/images/creative-agency/layer_img_4.jpeg',
          '/images/creative-agency/layer_img_5.jpeg',
        ]}
        data={testimonialData}
      />
      <section>
        <div className="container">
          <Cta
            title="Is there a specific project or goal that you have in mind?"
            btnText="Contact Us"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
       <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some recent news" subTitle="Our Blog" />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section> */}
    </>
  );
}
