import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. How do you ensure the content matches my brand’s voice?',
    content:
      'We begin each project with a detailed discovery session where we learn about your brand’s values, tone, and target audience. We then create content that reflects your unique brand voice.',
  },
  {
    title: '02. Can you write content for any industry?',
    content:
      'Yes! Our team of writers has experience across a wide range of industries. We conduct thorough research to ensure the content we create is accurate, relevant, and tailored to your specific industry.',
  },
  {
    title: '03. What is your process for SEO optimization?',
    content:
      'We integrate SEO best practices into every piece of content, including keyword research, proper use of headings, meta descriptions, and more. Our goal is to help your content rank higher in search engine results while maintaining quality and readability.',
  },
  {
    title: '04. How often should I update my website content?',
    content:
      ' Regularly updating your website content is crucial for SEO and maintaining audience engagement. We recommend reviewing and updating key pages every 6-12 months, while more dynamic content like blogs should be updated more frequently.',
  },
  {
    title: '05. What if I need content quickly?',
    content:
      ' We offer expedited services for clients who need content on short notice. Let us know your timeline, and we’ll work to meet your deadlines while maintaining our high standards of quality.',
  },
];
const serviceListData = [
  {
    title: 'Increased Organic Visibility',
    subtitle:
      ' Our SEO strategies improve your website’s search engine rankings, driving more high-quality traffic to your site.',
    imgUrl: '/images/sd/seo/1.png',
    href: '#',
  },
  {
    title: 'Enhanced User Experience',
    subtitle:
      ' We optimize site structure, speed, and content to create a seamless user experience that keeps visitors engaged and reduces bounce rates.',
    imgUrl: '/images/sd/seo/2.png',
    href: '#',
  },
  {
    title: 'Sustained Traffic Growth',
    subtitle:
      'By focusing on long-term SEO efforts, we deliver consistent organic traffic, reducing dependence on paid advertising.',
    imgUrl: '/images/sd/seo/3.png',
    href: '#',
  },
  {
    title: 'Improved Brand Credibility',
    subtitle:
      'Higher search rankings enhance your brand’s credibility, positioning you as a trusted authority in your industry.',
    imgUrl: '/images/sd/seo/4.png',
    href: '#',
  },
];

export default function SEO() {
  pageTitle('Search Engine Optimization');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Optimizing Visibility and Reach"
        subTitle="Search Engine Optimization"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/seo/SEO.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Engage your audience where they are most active with our Social Media and 
             Influencer Marketing services. We help you build authentic connections through 
             strategic content, targeted campaigns, and partnerships with influencers who 
             resonate with your brand. By amplifying your message across the right platforms, 
             we not only boost brand awareness but also foster genuine interactions that lead 
             to lasting customer loyalty and measurable growth.
            </p>
            <p>
             We focus on creating meaningful connections that drive real results. Our tailored 
             approach ensures your brand’s voice is heard by the right people, at the right time, 
             through the most impactful channels.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Dominate search rankings with our expert SEO services. 
                We optimize your website for visibility, driving organic 
                traffic that converts and keeping you ahead of the competition.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Comprehensive Site Audit"
                  subTitle="Identify technical issues and content gaps to optimize your site for search engines."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Keyword Research and Strategy"
                  subTitle="Select high-value keywords that balance search volume and competition effectively."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="  On-Page Optimization"
                  subTitle="Optimize content, meta tags, and headers to improve search engine relevance and rankings."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Content Development and Enhancement"
                  subTitle="Create and enhance content that engages your audience and meets SEO criteria."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Link Building and Off-Page SEO"
                  subTitle="Acquire high-quality backlinks to boost your site’s authority and search engine rankings."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title=" Continuous Monitoring and Reporting"
                  subTitle="Track key metrics and provide actionable insights for ongoing SEO optimization."
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
