import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. Which social media platforms should my business focus on?',
    content:
      'The right platforms depend on your target audience and business goals. We conduct thorough audience research to determine which platforms will be most effective for your brand.',
  },
  {
    title: '02. How do you measure the success of a social media campaign?',
    content:
      ' Success is measured using key metrics like engagement rates, follower growth, click-through rates, conversions, and ROI. We provide detailed reports and insights to track and optimize performance.',
  },
  {
    title: '03. How do you find the right influencers for my brand?',
    content:
      'We identify influencers based on their alignment with your brand values, audience demographics, engagement rates, and overall credibility. We focus on finding influencers who have genuine influence over your target market.',
  },
  {
    title: '04. Can you create content for all my social media channels?',
    content:
      'Yes, we offer comprehensive content creation services for all major social media platforms. We tailor the content to each platform’s unique requirements and your brand’s specific goals.',
  },
  {
    title: '05. What’s the difference between organic social media and paid social media?',
    content:
      'Organic social media involves growing your audience and engagement through regular, non-paid content. Paid social media involves using advertising to reach a larger, targeted audience quickly. We use a combination of both to maximize your brand’s reach and impact.',
  },
];
const serviceListData = [
  {
    title: 'Extended Brand Reach',
    subtitle:
      'Our social media and influencer strategies expand your brand’s reach, connecting you with larger, engaged audiences across platforms.',
    imgUrl: '/images/sd/ssm/1.png',
    href: '#',
  },
  {
    title: 'Authentic Audience Engagement',
    subtitle:
      'By partnering with influencers who resonate with your target market, we build authentic connections that foster trust and loyalty.',
    imgUrl: '/images/sd/ssm/2.png',
    href: '#',
  },
  {
    title: 'Boosted Brand Visibility',
    subtitle:
      ' Our creative and consistent social media content increases brand visibility, ensuring your message stands out in a crowded digital landscape.',
    imgUrl: '/images/sd/ssm/4.png',
    href: '#',
  },
  {
    title: 'Cost-Effective Marketing',
    subtitle:
      ' By leveraging influencers and targeted social campaigns, we maximize your ROI, delivering impactful results without overspending.',
    imgUrl: '/images/sd/ssm/5.png',
    href: '#',
  },
];

export default function SocialMediaMarketing() {
  pageTitle('Social Media Marketing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Connecting Brands with Audiences"
        subTitle="Social Media Marketing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/ssm/SSM.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Engage your audience where they are most active with our Social Media and 
             Influencer Marketing services. We help you build authentic connections through 
             strategic content, targeted campaigns, and partnerships with influencers who 
             resonate with your brand. By amplifying your message across the right platforms, 
             we not only boost brand awareness but also foster genuine interactions that lead 
             to lasting customer loyalty and measurable growth.
            </p>
            <p>
              We focus on creating meaningful connections that drive real results. Our tailored 
              approach ensures your brand’s voice is heard by the right people, at the right time, 
              through the most impactful channels.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Maximize brand visibility with targeted social media and influencer marketing. 
                Our SEO-focused campaigns build authentic connections, driving engagement and 
                setting you apart from competitors.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" In-Depth Audience Research"
                  subTitle="Understand your target audience’s behaviors and preferences to craft relevant content."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Strategic Content Planning"
                  subTitle="Develop a content calendar aligned with your brand’s goals and audience engagement."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title=" Influencer Alignment"
                  subTitle="Select influencers whose values and audiences match your brand for authentic partnerships."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Creative Content Development"
                  subTitle="Produce visually stunning and engaging content that drives interaction and sharing."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Data-Driven Optimization"
                  subTitle="Monitor performance metrics in real-time, adjusting strategies for maximum impact."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Comprehensive Reporting and Insights"
                  subTitle="Provide detailed reports with actionable insights to optimize future campaigns."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
