import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. How long does the branding and logo design process take?',
    content:
      '  The timeline for branding and logo design varies depending on the complexity of the project. Typically, it can take anywhere from a few weeks to a few months. We’ll provide a detailed timeline after understanding your specific needs.',
  },
  {
    title: '02. Can you help with rebranding an existing business?',
    content:
      'Yes! We offer comprehensive rebranding services that include updating your logo, refreshing your visual identity, and evolving your brand voice. Our goal is  to help you maintain the equity of your existing brand while making it more relevant and competitive.',
  },
  {
    title: '03. What if I’m not sure what I want my brand to look like?',
    content:
      'No problem! Our team will work with you to explore different concepts and ideas. We’ll provide creative direction and options that align with your business goals and resonate with your target audience.',
  },
  {
    title: '04. How do you ensure brand consistency across different platforms?',
    content:
      'We create detailed brand guidelines that outline how your brand should be represented across all platforms and materials. These guidelines ensure consistency in visual elements, messaging, and tone of voice.',
  },
  {
    title: '05. Do you offer ongoing support after the brand launch?',
    content:
      ' Absolutely! We offer ongoing support to help you adapt and evolve your brand as your business grows and market conditions change. This includes updating brand elements, refining messaging, and expanding into new markets.',
  },
];
const serviceListData = [
  {
    title: 'Distinct Brand Identity',
    subtitle:
      ' We create unique and memorable logos that encapsulate your brand’s essence, making a lasting impression on your audience.',
    imgUrl: '/images/sd/brandlogo/1.jpg',
    href: '#',
  },
  {
    title: 'Improved Brand Recognition',
    subtitle:
      'Our designs enhance brand visibility, helping your business stand out in a crowded market and fostering customer recall.',
    imgUrl: '/images/sd/brandlogo/2.jpg',
    href: '#',
  },
  {
    title: 'Consistent Brand Messaging',
    subtitle:
      'Our branding solutions ensure consistency across all touchpoints, reinforcing your brand’s message and values.',
    imgUrl: '/images/sd/brandlogo/4.jpg',
    href: '#',
  },
  {
    title: 'Strategic Brand Positioning',
    subtitle:
      'We align your brand identity with your business goals, positioning you effectively in the market to attract your ideal customers.',
    imgUrl: '/images/sd/brandlogo/5.jpg',
    href: '#',
  },
];

export default function BrandingAndLogo() {
  pageTitle('Branding & Logo Design');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Crafting Distinctive and Memorable Identities"
        subTitle="Branding & Logo Designing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/brandlogo/brandlogo.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             At Infosage Solutions, we offer comprehensive Branding and Logo 
             Design services that help you create a powerful and cohesive brand 
             identity. Our team of creative experts works closely with you to 
             develop a brand that not only reflects your values but also resonates 
             with your target audience. We focus on crafting a memorable logo that 
             serves as the cornerstone of your brand. This ensures it stands out in 
             a crowded marketplace, capturing attention and fostering recognition.
            </p>
            <br/>
            <p>
              Additionally, we work to define your brand voice and visual elements, 
              creating a consistent and compelling narrative that captures the essence 
              of your business. Our meticulous approach ensures that every aspect of 
              your brand is designed to make a lasting impact, connecting profoundly 
              with the values and expectations of your target customers.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Create a memorable brand identity with custom logo 
                design and branding. Our SEO-optimized approach ensures 
                your brand stands out, fostering recognition and trust in 
                a competitive market.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Brand Discovery and Research"
                  subTitle="Understand your brand’s mission, values, and audience to ensure alignment with your identity."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Concept Development"
                  subTitle="Generate multiple design concepts that reflect various aspects of your brand’s personality."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Collaborative Refinement"
                  subTitle="Work closely with you to refine the chosen design, ensuring it meets your vision and goals."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Comprehensive Brand Guidelines"
                  subTitle="Create detailed guidelines for consistent use across all brand touchpoints."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title=" Application Across Platforms"
                  subTitle="Ensure your new branding is consistently applied across digital and physical platforms."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Ongoing Brand Support"
                  subTitle="Provide continuous support to adapt and expand your branding as your business evolves."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
