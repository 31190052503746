import React from 'react';
import Spacing from '../Spacing';

import Service from '../Service';
import VideoModal from '../VideoModal';
import Accordion from '../Accordion';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';



const faqData = [
  {
    title: '01. How long does it take to develop a GTM strategy?',
    content:
      ' The timeline for developing a GTM strategy depends on the complexity of the product and the market. Typically, it can take anywhere from a few weeks to several months. We’ll provide a detailed timeline after assessing your specific needs.',
  },
  {
    title: '02. Can you help with international product launches?',
    content:
      '  Yes, we have experience in launching products in global markets. We conduct localized market research and develop strategies that account for cultural differences, regulatory requirements, and local market conditions.',
  },
  {
    title: '03. What happens if the market changes after the launch?',
    content:
      'Our strategies are designed to be flexible and adaptable. If there are significant market changes after the launch, we’ll work with you to adjust the strategy and ensure your product remains competitive.',
  },
  {
    title: '04. Do you offer post-launch support?',
    content:
      ' Absolutely. We provide ongoing support after the launch to monitor performance, gather feedback, and make necessary adjustments to the strategy. Our goal is to ensure long-term success, not just a successful launch.',
  },
  {
    title: '05. How do you ensure all teams are aligned during the launch?',
    content:
      'We facilitate cross-functional communication and collaboration throughout the entire process. Regular check-ins, clear documentation, and centralized project management tools help ensure that all teams are aligned and working towards the same goals.',
  },
];
const serviceListData = [
  {
    title: 'Extended Brand Reach',
    subtitle:
      'Our social media and influencer strategies expand your brand’s reach, connecting you with larger, engaged audiences across platforms.',
    imgUrl: '/images/sd/product/1.jpg',
    href: '#',
  },
  {
    title: 'Accelerated Time-to-Market',
    subtitle:
      'Our well-defined launch plans speed up your time-to-market, helping you capitalize on early opportunities and outpace competitors.',
    imgUrl: '/images/sd/product/2.jpg',
    href: '#',
  },
  {
    title: 'Maximized Market Penetration',
    subtitle:
      ' By identifying and focusing on the most lucrative customer segments, we ensure your product gains rapid traction and market share.',
    imgUrl: '/images/sd/product/3.jpg',
    href: '#',
  },
  {
    title: 'Risk Mitigation',
    subtitle:
      '  Through thorough market research and competitive analysis, we minimize launch risks, setting your product up for success.',
    imgUrl: '/images/sd/product/4.jpg',
    href: '#',
  },
];

export default function ProductLaunchStrategy() {
  pageTitle('Product Launch Strategy');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Driving Successful Market Entry"
        subTitle="Product Launch and GTM Strategy Service"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
      <div className="row align-items-center cs_gap_y_35">
          <div className="col-lg-5">
            <img
              src="/images/sd/product/product.jpg"
              alt="Thumb"
              className="cs_radius_15 w-100"
            />
          </div>
          <div className="col-lg-6 offset-lg-1">
            <h2 className="cs_fs_50">Overview</h2>
            <div className="cs_height_15 cs_height_lg_15" />
            <p >
             Launching a new product is a pivotal moment for any business, and our Product 
             Launch and Go-to-Market (GTM) Strategy service ensures you make a powerful 
             impact. We guide you through every step, from market research and positioning 
             to crafting compelling launch campaigns. Our tailored strategies are designed 
             to generate buzz, drive demand, and ensure your product reaches the right 
             audience at the perfect time, maximizing its potential for success.
            </p>
            <p>
              Our goal is to turn your product launch into a success story. We provide 
              a comprehensive, results-driven approach that aligns with your business 
              objectives, ensuring your product stands out in a competitive market.
            </p>
            <div className="cs_height_20 cs_height_lg_10" />
            
          </div>
          </div>
        </div>
      <Spacing lg="150" md="80" />
      

         <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="What you will get from this service?"
            subTitle=" "
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="75" />
          <div style={{display:'flex',justifyContent:'center'}}>
            <div style={{width:"55%"}}>
              <p>Accelerate your product's success with a tailored GTM strategy. 
                We create impactful, SEO-optimized launch plans that position your 
                product effectively and drive market penetration.
              </p>
            </div>
          </div>
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Market Research and Competitive Analysis"
                  subTitle="Identify opportunities and challenges through comprehensive research and competitor analysis."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Target Audience Segmentation"
                  subTitle="Segment and target audiences with precision, focusing on the most lucrative customer segments."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Strategic Positioning and Messaging"
                  subTitle="Craft unique value propositions that differentiate your product and resonate with the target audience."
                />
                
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title=" Channel and Tactic Selection"
                  subTitle="Choose the most effective marketing channels and tactics for maximum product visibility."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Launch Execution and Coordination"
                  subTitle="Coordinate all launch aspects, ensuring flawless execution and maximum impact."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title=" Post-Launch Analysis and Optimization"
                  subTitle="Analyze launch results and optimize strategies for sustained product success."
                />
                
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

       

      {/* <div className="container">
        <Spacing lg="130" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/tech-startup/hero_video_bg.jpeg"
          title="Our Design and Technology <br /> Studio is dedicated to making <br />your ideas a reality"
          titleVariant="cs_outline_text"
        />
      </div> */}

      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
