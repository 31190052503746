import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import Portfolio from '../Portfolio';
import CtaStyle2 from '../Cta/CtaStyle2';
import { pageTitle } from '../../helpers/PageTitle';
const portfolioData = [
  {
    href: '/portfolio/swethafoundation',
    imgUrl: '/images/portfolio/1.png',
    title: 'Swetha Foundation',
    btnText: 'See Project',
  },
  {
    href: '/portfolio/PNE',
    imgUrl: '/images/portfolio/2.png',
    title: 'PNE Good Foods',
    btnText: 'See Project',
  },
];

export default function PortfolioPage() {
  pageTitle('Portfolio');
  return (
    <>
      {/* <Spacing lg="70" md="70" /> */}
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="We have completed noteworthy projects"
        subTitle="Our Portfolio"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <Portfolio data={portfolioData} />
        <div className="cs_height_75 cs_height_lg_40" />
        <CtaStyle2
          title="Is there a specific project or goal <br />that you have in mind?"
          btnText="Send Message"
          btnUrl="/contact"
        />
        <div className="cs_height_150 cs_height_lg_80" />
      </div>
    </>
  );
}
